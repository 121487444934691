export enum Stage {
  ALPHA = 'alpha',
  BETA = 'beta',
  GAMMA = 'gamma',
  PERFORMANCE = 'performance',
  PROD_IAD = 'prod-iad',
  LCK_PROD = 'lck-prod',
  LCK_GAMMA = 'lck-gamma',
  DCA_GAMMA= 'dca-gamma',
  DCA_PROD = 'dca-prod',
  PROD_ZHY = 'prod-zhy',
  PROD_SIN = 'prod-sin',
  SIN_GAMMA = 'sin-gamma',
  PROD_NCL = 'prod-ncl',
  NCL_GAMMA = 'ncl-gamma',
  PROD_ALE = 'prod-ale',
  ALE_GAMMA = 'ale-gamma'
}

export const REGION_MAP: Record<string, string> = {
  'alpha': 'us-west-2',
  'beta': 'us-west-2',
  'gamma': 'us-west-2',
  'performance': 'us-west-2',
  'prod-iad': 'us-east-1',
  'lck-prod': 'us-isob-east-1',
  'lck-gamma': 'us-isob-east-1',
  'dca-gamma': 'us-iso-east-1',
  'dca-prod': 'us-iso-east-1',
  'prod-zhy': 'cn-northwest-1',
  'prod-sin': 'ap-southeast-1',
  'sin-gamma': 'ap-southeast-1',
  'prod-ncl': 'eu-isoe-west-1',
  'ncl-gamma': 'eu-isoe-west-1',
  'prod-ale': 'us-isof-south-1',
  'ale-gamma': 'us-isof-south-1'
}
    
export const COGNITO_POOL_ID_MAP: Record<string, string> = {
  'alpha': 'us-west-2:a5533cca-eb1f-4b49-af85-54a3197f013e',
  'beta': 'us-west-2:b00ae6d4-cc3b-4507-bf8d-c360d0d13292',
  'gamma': 'us-west-2:4efd817f-b011-42fc-a4c1-9734ce6187c4',
  'performance': 'us-west-2:34ef5398-5e3a-4e54-8224-3bb26961338b',
  'prod-iad': 'us-east-1:eed5f7ae-822b-40b5-a44d-ae404dd9db56',
  'prod-zhy': 'placeholder',
  'prod-sin': 'ap-southeast-1:19a61cc9-1614-4ad4-accc-9f3898610d05',
  'sin-gamma': 'ap-southeast-1:c1076ae8-efe9-4ca9-a234-dd5cccb53646'
};

export const UIAUTOMATION_ENDPOINT_MAP: Record<string, string> = {
  'alpha': 'https://6zik1goqo2.execute-api.us-west-2.amazonaws.com/Alpha/',
  'beta': 'https://7cvxq6m9i2.execute-api.us-west-2.amazonaws.com/Beta/',
  'gamma': 'https://dg0nxah3hj.execute-api.us-west-2.amazonaws.com/Gamma',
  'performance': 'https://tou6ee8onf.execute-api.us-west-2.amazonaws.com/Performance/',
  'prod-iad': 'https://1v4xo02c00.execute-api.us-east-1.amazonaws.com/Prod-IAD/',
  'lck-prod': 'https://fx2emzfrt1.execute-api.us-isob-east-1.sc2s.sgov.gov/LCK-MVP-Prime/',
  'lck-gamma': 'https://975gj2wtvf.execute-api.us-isob-east-1.sc2s.sgov.gov/lck-gamma/',
  'prod-zhy': 'https://zkokoqkb0b.execute-api.cn-northwest-1.amazonaws.com.cn/Prod-ZHY/',
  'dca-prod': 'https://5v5v71nneg.execute-api.us-iso-east-1.c2s.ic.gov/DCA-MVP-Prime/',
  'dca-gamma': 'https://p80x7huclj.execute-api.us-iso-east-1.c2s.ic.gov/dca-gamma/',
  'prod-sin': 'https://xqgjyaly6d.execute-api.ap-southeast-1.amazonaws.com/Prod-SIN/',
  'sin-gamma': 'https://tu3h4y0o6d.execute-api.ap-southeast-1.amazonaws.com/sin-gamma/',
  'prod-ncl': 'https://ztckgtx8ij.execute-api.eu-isoe-west-1.cloud.adc-e.uk/Prod-NCL/',
  'ncl-gamma': 'https://andp5q2veh.execute-api.eu-isoe-west-1.cloud.adc-e.uk/ncl-gamma/',
  'prod-ale': 'https://txbxnk9xu3.execute-api.us-isof-south-1.csp.hci.ic.gov/Prod-ALE',
  'ale-gamma': 'https://m0pxz839c1.execute-api.us-isof-south-1.csp.hci.ic.gov/ale-gamma'
}