import React from 'react';
import { SideNavigation } from '@amzn/awsui-components-react';
import { PageURL } from './Routes';

function Navigation() {
    return (
        <SideNavigation
            activeHref='/'
            header={{ text: 'UI Automation Portal', href: PageURL.HOME }}
            items={[
                { type: 'link', text: 'Home', href: PageURL.HOME},
                { type: 'link', text: 'Get Test Execution', href: PageURL.GET_TEST_EXECUTION},
                { type: 'link', text: 'Get Executions for a Test Id', href: PageURL.GET_TEST_EXECUTIONS_BY_TEST_ID },
                { type: 'link', text: 'Start Test Execution', href: PageURL.START_TEST_EXECUTION},
                { type: 'divider' },
                {
                    type: 'link',
                    text: 'Documentation',
                    href: 'https://w.amazon.com/bin/view/CP_Mercury/Madhatters/UIAutomation',
                    external: true
                }
            ]}
        />
    );
}

export default Navigation;