import * as React from "react";
import { Container, Header, Alert, Input, TextContent, Button } from "@amzn/awsui-components-react";
import { AxiosInstance } from "axios";
import { getApiClient } from '../../proxy/uiautomationclient';
import TestExecutionsTable from './TestExecutionsTable';

function TestExecutionsByTestIdFetcher() {
  const [testId, setTestId] = React.useState<string>("");
  const [lastEvaluatedKey, setLastEvaluatedKey] = React.useState<string>("");
  const [client, setClient] = React.useState<AxiosInstance | undefined>(undefined);
  const [executionsList, setExecutionsList] = React.useState<any>([]);
  const [responseError, setResponseError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  if (!client) {
    setClient(() => {
        return getApiClient() as AxiosInstance;
     });
  }

  const fetchInitialExecutions = async() => {
    setLoading(true);
    const returnedExecutions = await getExecutions(testId, "");
    setExecutionsList(returnedExecutions);
    setLoading(false);
  }

  const fetchFurtherExecutions = async() => {
    setLoading(true);
    const returnedExecutions = await getExecutions(testId, lastEvaluatedKey);
    setExecutionsList([...executionsList, ...returnedExecutions]);
    setLoading(false);
  }

  const getExecutions = async(testId : string, lastEvaluatedKey : string ) => {
    try {
      const response = await client?.post('/getTestExecutionsByTestId', { "testId": testId, "lastEvaluatedKey": lastEvaluatedKey });
      setLastEvaluatedKey(response?.data.lastEvaluatedKey);
      return response?.data.results;
    } catch (error) {
      setResponseError(JSON.stringify(error));
      setVisible(true);
    }
  }
  
  return (
    <Container
      header={<Header variant="h2">Get Test Executions by Test Id</Header>}>
      <Alert
        onDismiss={() => setVisible(false)}
        visible={visible}
        dismissAriaLabel="Close alert"
        header="Response error"
        type="error">
          {responseError}
      </Alert>
      <section>
        <div>
          <TextContent>
            Type Test Id
          </TextContent>
          <Input
            onChange={(e) => setTestId(e.detail.value)}
            value={testId}
          />
          <br />
          <Button
            onClick={fetchInitialExecutions}
            variant="normal"
            loading={loading}
          >
            Get Executions
          </Button>
        </div>
      </section>
      <br />
      <TestExecutionsTable 
        tableName={testId}
        executionsList={executionsList}
        loading={loading}
        fetchFurtherExecutions={fetchFurtherExecutions}
      />
    </Container>
  );
}

export default TestExecutionsByTestIdFetcher;