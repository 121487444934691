import {
    Input,
    InputProps,
} from '@amzn/awsui-components-react';
import React from 'react';
import {KeyValuePair} from "../config/constants";

interface ControlProps extends InputProps {
    prop: keyof KeyValuePair;
    value: string;
    index: number;
    placeholder: string;
    handleOnChange: (event: any) => void;
}

const Control = React.memo(({prop, value, index, placeholder, handleOnChange}: ControlProps) => {
    return (
        <Input
            value={value}
            placeholder={placeholder}
            onChange={({ detail }) => {
              handleOnChange((items: any) => {
                const updatedItems = [...items];
                updatedItems[index] = {
                    ...updatedItems[index],
                    [prop]: detail.value,
                };
                return updatedItems;
              });
            }}
        />
    );
});

export default Control;