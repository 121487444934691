import {
  Stage
} from "../config/config";
  
  /**
   * Determine stage based on URL.
   * URL do not seem to change frequently, hence for the most part this is going to work.
   */
export function determineStage(): string {
    switch (window.location.host) {
      case 'prod-iad.us-east-1.site.cp-uiautomation.platform.aws.dev':
        return Stage.PROD_IAD;
      case 'performance.us-west-2.site.cp-uiautomation.platform.aws.dev':
        return Stage.PERFORMANCE;
      case 'gamma.us-west-2.site.cp-uiautomation.platform.aws.dev':
        return Stage.GAMMA;
      case 'beta.us-west-2.site.cp-uiautomation.platform.aws.dev':
        return Stage.BETA;
      case 'lck-prod.us-isob-east-1.site.cp-uia.platform.sc2s-a2z.sgov.gov':
        return Stage.LCK_PROD;
      case 'lck-gamma.us-isob-east-1.site.cp-uia.platform.sc2s-a2z.sgov.gov':
        return Stage.LCK_GAMMA;
      case 'dca-prod.us-iso-east-1.site.cp-uia.platform.c2s-a2z.ic.gov':
        return Stage.DCA_PROD;
      case 'dca-gamma.us-iso-east-1.site.cp-uia.platform.c2s-a2z.ic.gov':
        return Stage.DCA_GAMMA;
      case 'prod-sin.ap-southeast-1.site.cp-uiautomation.platform.aws.dev':
        return Stage.PROD_SIN;
      case 'sin-gamma.ap-southeast-1.site.cp-uiautomation.platform.aws.dev':
        return Stage.SIN_GAMMA;
      case 'prod-ncl.eu-isoe-west-1.site.cp-uia.platform.a2z.adc-e.uk':
        return Stage.PROD_NCL;
      case 'ncl-gamma.eu-isoe-west-1.site.cp-uia.platform.a2z.adc-e.uk':
        return Stage.NCL_GAMMA;
      case 'prod-ale.us-isof-south-1.site.cp-uia.platform.a2z.hci.ic.gov':
        return Stage.PROD_ALE;
      case 'ale-gamma.us-isof-south-1.site.cp-uia.platform.a2z.hci.ic.gov':
        return Stage.ALE_GAMMA;
      default:
        // https://alpha.us-west-2.site.cp-uiautomation.platform.aws.dev/
        // For both alpha and local
        return Stage.ALPHA;
    }
}
