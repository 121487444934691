import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Home';
import GetTestExecution from '../features/get-test-execution/GetTestExecution';
import StartTestExecution from '../features/start-test-execution/StartTestExecution';
import TestExecutionFetcher from '../features/test-execution/TestExecutionFetcher';
import TestExecutionsByTestIdFetcher from '../features/get-test-executions-by-id/GetTestExecutionsByTestId';

export const PageURL = {
    HOME: '/',
    GET_TEST_EXECUTION: '/getExecution',
    GET_TEST_EXECUTIONS_BY_TEST_ID: '/getTestExecutionsByTestId',
    START_TEST_EXECUTION: '/startTestExecution',
    EXECUTION: '/execution',
    NOT_FOUND: '/404',
};

function PageRoutes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path={PageURL.NOT_FOUND}>
                    NOT FOUND.
                </Route>
                <Route exact path={PageURL.HOME}>
                    <Home />
                </Route>
                <Route exact path={PageURL.GET_TEST_EXECUTION}>
                    <GetTestExecution />
                </Route>
                <Route exact path={PageURL.GET_TEST_EXECUTIONS_BY_TEST_ID}>
                    <TestExecutionsByTestIdFetcher />
                </Route>
                <Route exact path={PageURL.START_TEST_EXECUTION}>
                    <StartTestExecution />
                </Route>
                <Route exact path={`${PageURL.EXECUTION}/:testId*/:executionId`}>
                    <TestExecutionFetcher />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}

export default PageRoutes;