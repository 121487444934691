import { MidwayIdentityCredentialProvider } from "@amzn/midway-identity-credential-provider";
import { AwsSigner } from 'aws-sign-web';
import axios, { AxiosRequestConfig } from "axios";

interface ValidatedHttpRequest {
  method: string;
  url: string;
}

type RequestConfig = AxiosRequestConfig & ValidatedHttpRequest;

export function makeClientWithMidwayCredentials(
  service: string,
  region: string,
  endpoint: string,
  cognitoPool: string
) {
  const httpClient = axios.create({ baseURL: endpoint });
  const credentials = MidwayIdentityCredentialProvider.newProvider({
    httpSigning: {
      scheme: "aws4-hmac-sha256",
      region: region,
      service: service,
    },
    midwayIdentity: {
      cognitoIdentityPoolId: cognitoPool,
    },
  });
  httpClient.interceptors.request.use(
    createSigV4MidwaySigningInterceptor(service, region, credentials)
  );

  return httpClient;
}

export function createSigV4MidwaySigningInterceptor(
  service: string,
  region: string,
  credentials: MidwayIdentityCredentialProvider
): (request: AxiosRequestConfig) => Promise<AxiosRequestConfig> {
  return (request: AxiosRequestConfig) => sigV4MidwaySigningInterceptor(service, region, credentials, request);
}

async function sigV4MidwaySigningInterceptor(
  service: string,
  region: string,
  credentials: MidwayIdentityCredentialProvider,
  request: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  if (request.url === undefined || request.method === undefined) throw new Error('HTTP method or url is unset.');
  request.headers = extractAndUpdateHeaders(request);
  await credentials.getPromise();
  return await signRequest(service, region, credentials, request as RequestConfig);
}

function extractAndUpdateHeaders(request: AxiosRequestConfig): Partial<Headers> {
  // Replace Axios HTTP headers object while respecting their header's order precedence
  // Precendence: custom headers > [HTTP-method specific headers] > common headers
  const existingHeaders = Object.keys(request.headers)
    .filter(
      header =>
        header === 'common' ||
        header === request.method ||
        !['head', 'get', 'post', 'patch', 'delete', 'put'].includes(header)
    )
    .sort(first => (first === 'common' ? 2 : first === request.method ? 1 : 0))
    .reduce((acc: Partial<Headers>, name: string) => {
        if (name === 'common' || name === request.method) {
          Object.assign(acc, request.headers[name]);
        } else {
          acc[name as keyof Partial<Headers>] = request.headers[name];
        }
        return acc;
      },
      {} as Partial<Headers>
    );

  return existingHeaders;
}

async function signRequest(
  service: string,
  region: string,
  credentials: MidwayIdentityCredentialProvider,
  request: RequestConfig
): Promise<RequestConfig> {
  return signRequestWithCredentials(service, region, credentials, request);
}

function signRequestWithCredentials(
  service: string,
  region: string,
  credentials: MidwayIdentityCredentialProvider,
  request: RequestConfig
): RequestConfig {

  const headers = new AwsSigner({
    service: service,
    region: region,
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken
  }).sign({ ...request, url: createUrl(request) });
  Object.assign(request.headers, headers);

  return request;
}

function createUrl(request: RequestConfig): string {
  const { url, baseURL } = request

  return `${baseURL!.replace(/\/*$/, "")}/${url.replace(/^\/*/, "")}`
}