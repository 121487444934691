import { Modal, Spinner } from '@amzn/awsui-components-react';
import React, { ComponentType, useState } from 'react';

export interface LoadingProps {
  message: string;
  visible: boolean;
}

function Loading({ message, visible } : LoadingProps) {
  return (
    <Modal
      closeAriaLabel=""
      visible={visible}
      size="medium"
      header={message}
    >
      <Spinner size="large" />
    </Modal>
  )
}


interface withLoadingProps {
  setLoading: (loading: boolean) => void;
}

const withLoading = <P extends withLoadingProps>(WrappedComponent: ComponentType<P>, message: string) => (props: any) => {
  const [ loading, setLoading] = useState(false);
  return (
    <>
      <Loading message={message} visible={loading}/>
      <WrappedComponent {...props as any} setLoading={setLoading} />
    </>
  );
}

export default withLoading;