import React from 'react';
import { AppLayout } from "@amzn/awsui-components-react";
import PageRoutes from "./components/Routes";
import Navigation from "./components/Navigation";
import '@amzn/awsui-global-styles/polaris.css';

function App() {
  return (
    <div className="App">
        <AppLayout
            content={<PageRoutes />}
            navigation={<Navigation />}
        />
    </div>
  );
}

export default App;