import React from 'react';
import { Box } from "@amzn/awsui-components-react";

export interface EmptyStateProps {
  title: string,
  subtitle: string
}
  
function EmptyState( props: EmptyStateProps ) {
  return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {props.title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {props.subtitle}
        </Box>
      </Box>
  );
}

export default EmptyState;