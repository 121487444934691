import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// NOTE: to avoid to render twice during development you can disable StrictMode
// @see https://andreasheissenberger.medium.com/react-components-render-twice-any-way-to-fix-this-91cf23961625
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
