import { Box, ColumnLayout, Container, Grid, Header, Link, Select } from '@amzn/awsui-components-react';
import React from 'react';
import { ENVIRONMENTS, getSelectedEnvironment } from '../config/environments';
import { PageURL } from './Routes';
import '../styles/landing-page.scss';

const HomeHeader = () => {
  const [
    selectedOption,
  ] = React.useState({ label: getSelectedEnvironment(), value: getSelectedEnvironment() });

  const onEnvironmentChange = (targetEnvironmentUrl: string) => {
    window.location.href = targetEnvironmentUrl;
  }

  return (
    <div className="custom-home__header">
      <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
        <div style={{ width: "10%", float: "right" }}>
          <Select
            selectedOption={selectedOption}
            onChange={({ detail }) => onEnvironmentChange(detail.selectedOption.value || getSelectedEnvironment())}
            options={ENVIRONMENTS.map(e => ({ label: e.name, value: e.url }))}
            selectedAriaLabel="Selected"
          />
        </div>
        <Grid
          gridDefinition={[
            { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
            { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
            { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } }
          ]}
        >
          <Box fontWeight="light" padding={{ top: 'xs' }}>
            <span className="custom-home__category">AWSCP Atlas Team</span>
          </Box>
          <div className="custom-home__header-title">
            <Box key="title" variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
              UIAutomation Console
            </Box>
            <Box key="subtitle" fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
              Automatic UI Testing
            </Box>
          </div>
        </Grid>
      </Box>
    </div>
  );
}

const ActionMenu = () => (
  <div className="custom-home-main-content-area">
    <Container>
      <ColumnLayout columns={2} variant="text-grid">
        <div>
          <Box variant="h3" padding={{ top: 'n' }}>
            <Link fontSize="heading-m" href={PageURL.HOME} >Start attribute-based test for an entity </Link>
          </Box>
          <Box variant="p">Trigger a new test suite to validate attributes for an entity.</Box>
        </div>
        <div>
          <Box variant="h3" padding={{ top: 'n' }}>
            <Link fontSize="heading-m" href={PageURL.HOME} >Get entity run (execution) history</Link>
          </Box>
          <Box variant="p">Get test execution history by entityArn.</Box>
        </div>
        <div>
          <Box variant="h3" padding={{ top: 'n' }}>
            <Link fontSize="heading-m" href={PageURL.HOME}>Get test suite history</Link>
          </Box>
          <Box variant="p">
            Get test suite history. A test suite is a logic group that aggregate all the test being executed for an entity.
          </Box>
        </div>
        <div>
          <Box variant="h3" padding={{ top: 'n' }}>
            <Link fontSize="heading-m" href={PageURL.HOME}>Get test suite run (execution) details</Link>
          </Box>
          <Box variant="p">Get details for a test suite run to see detailed information about test case executed</Box>
        </div>
        <div>
          <Box variant="h3" padding={{ top: 'n' }}>
            <Link fontSize="heading-m" href={PageURL.HOME} >Get test case run (execution) details</Link>
          </Box>
          <Box variant="p">Get details for a single test case execution by using the test case run identifier.</Box>
        </div>
      </ColumnLayout>
    </Container>
  </div>
);

const Home = () => {
  return (
    <Box margin={{ bottom: 'l' }}>
      <HomeHeader />
      <Box margin={{ top: 's' }} padding={{ top: 'xxl', horizontal: 's' }}>
        <ColumnLayout columns={2} variant="text-grid">
          <ActionMenu />
          <Container
            header={
              <Header variant="h2">
                Help
              </Header>
            }>
            <ColumnLayout columns={1} variant="text-grid">
              <Box key="Wiki" variant="h3" padding={{ top: 'xxxs' }}>
                <Link fontSize="heading-m"
                  href={'https://w.amazon.com/bin/view/CP_Mercury/Madhatters/UIAutomation'}>Wiki page</Link>
              </Box>
              <Box key="Help" variant="h3" padding={{ top: 'xxxs' }}>
                <Link fontSize="heading-m"
                  href={'https://w.amazon.com/bin/view/CP_Mercury/Madhatters/UIAutomation/HowTo/'}>Help page</Link>
              </Box>
            </ColumnLayout>
          </Container>
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default Home;