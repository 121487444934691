import * as React from 'react';
import {
    Box, Header,
    Table, Link,
    Pagination,
    TextFilter,
    SpaceBetween,
    Button, StatusIndicator
} from "@amzn/awsui-components-react";
import { useCollection } from '@amzn/awsui-collection-hooks';
import { PageURL } from '../../components/Routes';
import EmptyState from '../../common/EmptyState';
import { getMatchesCountText, paginationLabels } from '../../config/labels';
import {StatusIndicatorProps} from "@amzn/awsui-components-react/polaris/status-indicator/internal";

function filterTable (item: any, filteringText: string, filteringFields: string[] | undefined) {
    if (!filteringText || filteringText === '') {
        return true;
    }
    
    let reg = new RegExp(filteringText, "gi");
    let executionId = item.executionId;
    let status = item.status;
    let startedTestsAt = item.startedTestsAt;
    let endedTestsAt = item.endedTestsAt;

    switch (true) {
        case executionId && reg.test(executionId):
        case status && reg.test(status):
        case startedTestsAt && reg.test(startedTestsAt):
        case endedTestsAt && reg.test(endedTestsAt):
            return true;
        default:
            return false;
    }
}

export interface TestExecutionsTableProps {
    tableName: string,
    executionsList: any[],
    loading: boolean,
    fetchFurtherExecutions: () => void
}

function getStatusIndicator(status: string): StatusIndicatorProps.Type {
  if (status === 'queued') return 'pending';
  if (status === 'running') return 'in-progress';
  if (status === 'success') return 'success';

  return "error";
}

function TestExecutionsTable(props: TestExecutionsTableProps) {
    const { tableName, executionsList, loading, fetchFurtherExecutions } = props;
    const { items, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        executionsList,
        {
          filtering: {
            empty: (
              <EmptyState
                title="No executions"
                subtitle="No executions to display"
              />
            ),
            noMatch: (
              <EmptyState
                title="No matches"
                subtitle="Can’t find a match"
              />
            ),
            filteringFunction: filterTable
          },
          pagination: { pageSize: 10 },
          sorting: {},
          selection: {},
        }
    );

    return (
      <Table
        {...collectionProps}
        trackBy="executionId"
        stickyHeader={true}
        columnDefinitions={[
            {
              id: "executionId",
              header: "Execution Id",
              cell: item => <Link href={`${PageURL.EXECUTION}/${item.testId}/${item.executionId}`}>{item.executionId}</Link>
            },
            { 
              id: "status",
              header: "status",
              cell: item => (
                <StatusIndicator type={getStatusIndicator(item.status)}>
                  {item.status}
                </StatusIndicator>
              )
            },
            { id: "startedTestsAt", sortingField: 'startedTestsAt', header: "Started At", cell: item => item.startedTestsAt },
            { id: "endedTestsAt", sortingField: 'endedTestsAt', header: "Ended At", cell: item => item.endedTestsAt }
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
            <TextFilter
              {...filterProps}
              countText={getMatchesCountText(filteredItemsCount!)}
              filteringAriaLabel="Filter instances"
            />
        }
        loadingText="Loading executions"
        empty={
            <Box textAlign="center" color="inherit">
                <b>No resources</b>
                <Box
                padding={{ bottom: "s" }}
                variant="p"
                color="inherit"
                >
                No executions to display.
                </Box>
            </Box>
        }
        header={
            <Header
              variant='h1'
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    onClick={fetchFurtherExecutions}
                    variant="normal"
                    loading={loading}
                  >
                    Get more executions
                  </Button>
                </SpaceBetween>
              }
            >
                {tableName}
            </Header>
        }
      />
    );
};

export default TestExecutionsTable;