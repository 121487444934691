import * as React from "react";
import {
    Button, 
    TextContent, 
    Container, 
    Header,
    AttributeEditor,
    Input,
    Alert
} from "@amzn/awsui-components-react";
import { useHistory } from "react-router-dom";
import { AxiosInstance } from "axios";
import { getApiClient } from '../../proxy/uiautomationclient';
import Control from '../../common/Control'
import { PageURL } from "../../components/Routes";

export const StartTestExecution = () => {
    const [client, setClient] = React.useState<AxiosInstance | undefined>(undefined);
    const [loading, setLoading] = React.useState(false);
    const [testId, setTestId] = React.useState('');
    const [inputs, setInputs] = React.useState([{key: '', value: ''}]);
    const [responseError, setResponseError] = React.useState<string>();
    const [visible, setVisible] = React.useState(false);
    const history = useHistory();

    if (!client) {
        setClient(() => {
            return getApiClient() as AxiosInstance;
         });
    }

    const executeTestCase = async () => {
        setLoading(true);
        let transformedInputs = inputs.reduce((result, input) => ({...result, [input.key]: input.value}), {});
        try {
          const response = await client?.post('/executeTestCase', { "testId": testId, "inputs": transformedInputs});
          const executionId = response?.data.executionId;
          history.push(`${PageURL.EXECUTION}/${testId}/${executionId}`);
        } catch (error) {
          setResponseError(JSON.stringify(error));
          setVisible(true);
        } finally {
          setLoading(false);
        }
    };

    const onAddButtonClick = React.useCallback(() => {
      setInputs(inputs => [...inputs, {key: '', value: ''} ]);
    }, []);
    
    const onRemoveButtonClick = React.useCallback(({ detail: { inputIndex } }) => {
      setInputs(inputs => {
          const newInputs = inputs.slice();
          newInputs.splice(inputIndex, 1);
          return newInputs;
        });
    }, []);

    const definition = React.useMemo(
      () => [
        {
          label: 'Key label',
          control: ({ key = '' }, inputIndex: number) => (
            <Control prop="key" value={key} index={inputIndex} placeholder="Enter key" handleOnChange={setInputs} />
          )
       },
       {
          label: 'Value label',
          control: ({ value = '' }, inputIndex: number) => (
            <Control prop="value" value={value} index={inputIndex} placeholder="Enter value" handleOnChange={setInputs} />
          )
        },
      ],
      []
    );

    return (
        <div>
            <Container className="onboarding-container" header={<Header variant="h2">Start Test Execution</Header>}>
                <section>
                    <Alert
                      onDismiss={() => setVisible(false)}
                      visible={visible}
                      dismissAriaLabel="Close alert"
                      header="Response error"
                      type="error">
                        {responseError}
                    </Alert>
                    <div>
                        <TextContent>
                          Enter Test Id
                        </TextContent>
                        <Input
                            onChange={(e) => setTestId(e.detail.value)}
                            value={testId}
                        />
                        <TextContent>
                          Add Inputs
                        </TextContent>
                        <AttributeEditor
                          onAddButtonClick={onAddButtonClick}
                          onRemoveButtonClick={onRemoveButtonClick}
                          definition={definition}
                          items={inputs}
                          addButtonText="Add new input"
                          removeButtonText="Remove"
                          empty="No inputs added"
                        />
                        <br />
                        <Button
                            onClick={executeTestCase}
                            variant="normal"
                            loading={loading}
                        >
                            Start Execution
                        </Button>
                        <br />
                        <br />
                        <br />
                    </div>
                </section>
            </Container>
        </div>
    );
}

export default StartTestExecution;