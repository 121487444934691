import {
  REGION_MAP,
  COGNITO_POOL_ID_MAP,
  UIAUTOMATION_ENDPOINT_MAP,
} from "../config/config";
import { determineStage } from "./utils";
import axios, { AxiosInstance } from "axios";
import { aws4Interceptor } from "aws4-axios";
import { makeClientWithMidwayCredentials } from "./signing-utils";

const stage = determineStage();

export function getApiClient(): AxiosInstance {
  if (localStorage.getItem("__credentials__") !== null) {
    //Credentials are loaded by UILambda.
    let credentials = getCredentials();
    const client = axios.create({
      baseURL: UIAUTOMATION_ENDPOINT_MAP[stage],
    });

    const interceptor = aws4Interceptor(
      {
        region: REGION_MAP[stage],
        service: "execute-api",
      },
      {
        accessKeyId: credentials.AccessKeyId,
        secretAccessKey: credentials.SecretAccessKey,
        sessionToken: credentials.SessionToken,
      }
    );

    client.interceptors.request.use(interceptor);

    return client;
  }

  return makeClientWithMidwayCredentials(
    "execute-api",
    REGION_MAP[stage],
    UIAUTOMATION_ENDPOINT_MAP[stage],
    COGNITO_POOL_ID_MAP[stage]
  );
}

function getCredentials() {
  return JSON.parse(localStorage.getItem("__credentials__")!);
}
