import * as React from "react";
import {
    Button, 
    TextContent, 
    Container, 
    Header,
    Input
} from "@amzn/awsui-components-react";
import { useHistory } from "react-router-dom";
import { PageURL } from "../../components/Routes";

export const GetTestExecution = () => {
    const [testId, setTestId] = React.useState('');
    const [execId, setExecId] = React.useState('');
    const history = useHistory();

    const getExecution = async () => {
        history.push(`${PageURL.EXECUTION}/${testId}/${execId}`);
    };

    return (
        <div>
            <Container className="onboarding-container" header={<Header variant="h2">Get Test Case Run</Header>}>
                <section>
                    <div>
                        <TextContent>
                          Type Test Id
                        </TextContent>
                        <Input
                            onChange={(e) => setTestId(e.detail.value)}
                            value={testId}
                        />
                        <TextContent>
                          Type Execution Id
                        </TextContent>
                        <Input
                          onChange={(e) => setExecId(e.detail.value)}
                          value={execId}
                        />
                        <br />
                        <Button
                            onClick={getExecution}
                            variant="normal"
                        >
                            Get Execution
                        </Button>
                    </div>
                </section>
            </Container>
        </div>
    );
}

export default GetTestExecution;