export const ENVIRONMENTS = [
    { name: 'ALPHA', url: 'https://alpha.us-west-2.site.cp-uiautomation.platform.aws.dev/'},
    { name: 'BETA', url: 'https://beta.us-west-2.site.cp-uiautomation.platform.aws.dev'},
    { name: 'GAMMA', url: 'https://gamma.us-east-1.site.cp-uiautomation.platform.aws.dev'},
    { name: 'SIN-GAMMA', url: 'https://sin-gamma.ap-southeast-1.site.cp-uiautomation.platform.aws.dev'},
    // { name: 'PROD-PDX', url: 'https://prod-pdx.us-west-2.site.cp-uiautomation.platform.aws.dev'},
    { name: 'PROD-IAD', url: 'https://prod-iad.us-east-1.site.cp-uiautomation.platform.aws.dev'},
    { name: 'PROD-SIN', url: 'https://prod-sin.ap-southeast-1.site.cp-uiautomation.platform.aws.dev'},
    { name: 'PROD-NCL', url: 'https://prod-ncl.eu-isoe-west-1.site.cp-uia.platform.a2z.adc-e.uk'},
    { name: 'PROD-ALE', url: 'https://prod-ale.us-isof-south-1.site.cp-uia.platform.a2z.hci.ic.gov'}
  ]
  
  export const LOCAL_ENVIRONMENT = { name: 'LOCAL', url: 'https://localhost.integ.amazon.com:3000/'};
  
  export function getSelectedEnvironment() {
    const index = ENVIRONMENTS.findIndex(e => e.name.toLocaleLowerCase() === window.location.host.split('.')[0])
  
    if (index >= 0) {
      return ENVIRONMENTS[index].name;
    }
    return LOCAL_ENVIRONMENT.name;
  }